export const ArrowLeft = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.307554 9.50891L9.50896 0.307513C9.91902 -0.102504 10.583 -0.102504 10.9931 0.307513C11.4031 0.7172 11.4031 1.38195 10.9931 1.79163L2.53369 10.251L10.993 18.7103C11.403 19.12 11.403 19.7847 10.993 20.1944C10.788 20.3995 10.5196 20.502 10.251 20.502C9.98229 20.502 9.71356 20.3995 9.50891 20.1944L0.307507 10.993C-0.102512 10.5833 -0.10251 9.9186 0.307554 9.50891Z"
      fill="white"
    />
    <path
      d="M9.00696 9.50891L18.2084 0.307513C18.6181 -0.102504 19.2828 -0.102504 19.6925 0.307513C20.1025 0.7172 20.1025 1.38195 19.6925 1.79163L11.2335 10.251L19.6924 18.7103C20.1025 19.12 20.1025 19.7847 19.6924 20.1944C19.4878 20.3995 19.2191 20.502 18.9504 20.502C18.6817 20.502 18.413 20.3995 18.2083 20.1944L9.00692 10.993C8.59695 10.5833 8.59695 9.9186 9.00696 9.50891Z"
      fill="white"
    />
  </svg>
)

export const ArrowRight = ({ className }: { className?: string }) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M20.211 9.5089L10.8896 0.307513C10.4742 -0.102504 9.80151 -0.102504 9.38609 0.307513C8.97073 0.717199 8.97073 1.38195 9.38609 1.79163L17.9558 10.251L9.38614 18.7103C8.97078 19.12 8.97078 19.7847 9.38614 20.1944C9.59385 20.3994 9.8657 20.502 10.1379 20.502C10.4101 20.502 10.6823 20.3994 10.8896 20.1944L20.211 10.993C20.6264 10.5833 20.6264 9.91859 20.211 9.5089Z"
      fill="white"
    />
    <path
      d="M11.3981 9.5089L2.07672 0.307513C1.66169 -0.102504 0.988271 -0.102504 0.573242 0.307513C0.157878 0.717199 0.157878 1.38195 0.573242 1.79163L9.14256 10.251L0.57329 18.7103C0.157926 19.12 0.157926 19.7847 0.57329 20.1944C0.780613 20.3994 1.05284 20.502 1.32503 20.502C1.59721 20.502 1.86944 20.3994 2.07677 20.1944L11.3982 10.993C11.8135 10.5833 11.8135 9.91859 11.3981 9.5089Z"
      fill="white"
    />
  </svg>
)

export const Discussion = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 16.9189C25 13.275 22.5447 10.0912 19.0823 9.13181C18.8646 4.0596 14.6708 0 9.5459 0C4.28219 0 0 4.28219 0 9.5459C0 11.2614 0.456619 12.9316 1.3237 14.398L0.0350952 19.0565L4.69379 17.7681C6.04172 18.5652 7.56226 19.0142 9.13162 19.0819C10.0908 22.5445 13.2748 25 16.9189 25C18.3735 25 19.788 24.6126 21.0308 23.8766L24.9647 24.9647L23.8766 21.0308C24.6126 19.788 25 18.3735 25 16.9189ZM4.92496 16.1842L2.13604 16.9558L2.90756 14.1668L2.7317 13.8918C1.90296 12.5952 1.46484 11.0924 1.46484 9.5459C1.46484 5.08995 5.08995 1.46484 9.5459 1.46484C14.0018 1.46484 17.627 5.08995 17.627 9.5459C17.627 14.0018 14.0018 17.627 9.5459 17.627C7.99942 17.627 6.49681 17.1888 5.2 16.3601L4.92496 16.1842ZM22.864 22.864L20.7945 22.2914L20.5181 22.4712C19.4468 23.1672 18.202 23.5352 16.9189 23.5352C14.0549 23.5352 11.5381 21.6833 10.6464 19.0279C15.0322 18.5223 18.5223 15.0322 19.0281 10.6462C21.6833 11.5381 23.5352 14.0549 23.5352 16.9189C23.5352 18.202 23.1672 19.4468 22.4712 20.5181L22.2914 20.7945L22.864 22.864Z"
      fill="white"
    />
  </svg>
)

export const Docs = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3771 8.31726C18.102 8.31726 17.8789 8.09861 17.8789 7.82899V4.49023C17.8789 4.2206 18.102 4.00195 18.3771 4.00195C18.6522 4.00195 18.8752 4.2206 18.8752 4.49023V7.82899C18.8752 8.09861 18.6522 8.31726 18.3771 8.31726Z"
      fill="white"
    />
    <path
      d="M18.3771 3.40723C18.6522 3.40723 18.8752 3.18862 18.8752 2.91895C18.8752 2.64928 18.6522 2.43066 18.3771 2.43066C18.1019 2.43066 17.8789 2.64928 17.8789 2.91895C17.8789 3.18862 18.1019 3.40723 18.3771 3.40723Z"
      fill="white"
    />
    <path
      d="M23.6092 5.12974C23.6092 2.30117 21.2619 0 18.3766 0C17.1823 0 16.0803 0.394238 15.1986 1.05693H2.64111C1.64144 1.05693 0.828125 1.85405 0.828125 2.83394V20.5963C0.828125 21.5761 1.64144 22.3733 2.64111 22.3733H4.22185V23.2229C4.22185 24.2028 5.03516 25 6.03484 25H19.06C20.0597 25 20.873 24.2028 20.873 23.2229V16.4504C20.873 16.1808 20.6499 15.9621 20.3748 15.9621C20.0997 15.9621 19.8767 16.1808 19.8767 16.4504V23.2229C19.8767 23.6643 19.5103 24.0234 19.06 24.0234H6.03484C5.58454 24.0234 5.21819 23.6644 5.21819 23.2229V22.3733H15.6663C16.666 22.3733 17.4794 21.5762 17.4794 20.5963V10.184C17.771 10.2336 18.0707 10.2595 18.3766 10.2595C18.8978 10.2595 19.4014 10.1844 19.8767 10.0447V12.0358C19.8767 12.3054 20.0998 12.5241 20.3749 12.5241C20.65 12.5241 20.873 12.3054 20.873 12.0358V9.63711C22.5017 8.7667 23.6092 7.07266 23.6092 5.12974V5.12974ZM16.483 20.5963C16.483 21.0377 16.1167 21.3968 15.6663 21.3968H2.64111C2.19082 21.3968 1.82446 21.0377 1.82446 20.5963V2.83394C1.82446 2.39258 2.19082 2.0335 2.64111 2.0335H14.2071C13.5818 2.84077 13.1946 3.83442 13.1486 4.91309H4.66527C4.39018 4.91309 4.1671 5.13174 4.1671 5.40137V7.46357C4.1671 7.7332 4.39018 7.95186 4.66527 7.95186H13.6422C13.7554 7.95186 13.8598 7.91479 13.9435 7.85239C14.5386 8.78032 15.4284 9.50918 16.483 9.91191V20.5963ZM13.144 5.88965V6.97529H5.16344V5.88965H13.144ZM18.3766 9.28286C16.0407 9.28286 14.1403 7.41978 14.1403 5.12969C14.1403 2.83965 16.0407 0.976514 18.3766 0.976514C20.7125 0.976514 22.6129 2.8396 22.6129 5.12969C22.6129 7.41978 20.7125 9.28286 18.3766 9.28286Z"
      fill="white"
    />
    <path
      d="M20.374 14.7227C20.1688 14.7227 19.9806 14.5939 19.9083 14.4061C19.834 14.2135 19.8948 13.9869 20.0582 13.8566C20.2218 13.726 20.4574 13.7104 20.6369 13.8195C20.8161 13.9285 20.9078 14.1435 20.8593 14.3451C20.8069 14.5632 20.6029 14.7227 20.374 14.7227Z"
      fill="white"
    />
    <path
      d="M13.6431 10.627H4.66614C4.39105 10.627 4.16797 10.4083 4.16797 10.1387C4.16797 9.86904 4.39105 9.65039 4.66614 9.65039H13.6431C13.9182 9.65039 14.1412 9.86904 14.1412 10.1387C14.1412 10.4083 13.9182 10.627 13.6431 10.627Z"
      fill="white"
    />
    <path
      d="M13.6431 12.9432H4.66614C4.39105 12.9432 4.16797 12.7246 4.16797 12.455C4.16797 12.1854 4.39105 11.9668 4.66614 11.9668H13.6431C13.9182 11.9668 14.1412 12.1854 14.1412 12.455C14.1412 12.7246 13.9182 12.9432 13.6431 12.9432Z"
      fill="white"
    />
    <path
      d="M13.6431 15.2588H4.66614C4.39105 15.2588 4.16797 15.0401 4.16797 14.7705C4.16797 14.5009 4.39105 14.2822 4.66614 14.2822H13.6431C13.9182 14.2822 14.1412 14.5009 14.1412 14.7705C14.1412 15.0402 13.9182 15.2588 13.6431 15.2588Z"
      fill="white"
    />
    <path
      d="M13.6431 17.5742H4.66614C4.39105 17.5742 4.16797 17.3556 4.16797 17.0859C4.16797 16.8163 4.39105 16.5977 4.66614 16.5977H13.6431C13.9182 16.5977 14.1412 16.8163 14.1412 17.0859C14.1412 17.3556 13.9182 17.5742 13.6431 17.5742Z"
      fill="white"
    />
  </svg>
)

export const ExitIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.97344 14.6443H1.99334C1.62656 14.6443 1.32891 14.3466 1.32891 13.9799V2.01974C1.32891 1.65296 1.62659 1.3553 1.99334 1.3553H7.97344C8.34087 1.3553 8.63787 1.0583 8.63787 0.690867C8.63787 0.32343 8.34087 0.0263672 7.97344 0.0263672H1.99334C0.894344 0.0263672 0 0.920742 0 2.01974V13.9799C0 15.0789 0.894344 15.9732 1.99334 15.9732H7.97344C8.34087 15.9732 8.63787 15.6762 8.63787 15.3088C8.63787 14.9413 8.34087 14.6443 7.97344 14.6443Z"
      fill="white"
    />
    <path
      d="M15.802 7.52713L11.7622 3.54041C11.5017 3.2826 11.0805 3.28594 10.8226 3.54707C10.5648 3.80819 10.5675 4.22879 10.8293 4.4866L13.7163 7.33576H5.98011C5.61267 7.33576 5.31567 7.63276 5.31567 8.00019C5.31567 8.36763 5.61267 8.66466 5.98011 8.66466H13.7163L10.8293 11.5138C10.5675 11.7716 10.5655 12.1922 10.8226 12.4534C10.9529 12.5849 11.1243 12.6514 11.2957 12.6514C11.4645 12.6514 11.6333 12.5876 11.7622 12.46L15.802 8.47326C15.9283 8.34835 16 8.17823 16 8.00016C16 7.82216 15.929 7.65272 15.802 7.52713Z"
      fill="white"
    />
  </svg>
)

export const Github = () => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.649 0C5.664 0 0 5.664 0 12.65c0 5.59 3.624 10.33 8.651 12.004.633.116.864-.275.864-.61 0-.3-.011-1.096-.018-2.151-3.518.764-4.26-1.696-4.26-1.696-.576-1.461-1.405-1.85-1.405-1.85-1.149-.785.087-.77.087-.77 1.27.09 1.937 1.304 1.937 1.304 1.128 1.933 2.961 1.375 3.682 1.052.115-.818.441-1.376.803-1.692-2.809-.319-5.762-1.405-5.762-6.252 0-1.38.493-2.51 1.302-3.394-.13-.32-.565-1.606.123-3.348 0 0 1.063-.34 3.48 1.297 1.008-.28 2.09-.421 3.166-.426 1.074.005 2.157.145 3.167.426 2.415-1.637 3.475-1.297 3.475-1.297.69 1.742.257 3.028.126 3.348.811.884 1.301 2.013 1.301 3.394 0 4.86-2.958 5.93-5.776 6.242.454.39.859 1.163.859 2.343 0 1.69-.016 3.055-.016 3.47 0 .338.228.732.87.609 5.023-1.677 8.644-6.415 8.644-12.002C25.3 5.664 19.636 0 12.649 0Z"
      fill="#fff"
    />
  </svg>
)

export const Profile = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3388 3.66116C18.9779 1.30024 15.8388 0 12.5 0C9.161 0 6.02207 1.30024 3.66116 3.66116C1.30024 6.02207 0 9.161 0 12.5C0 15.8388 1.30024 18.9779 3.66116 21.3388C6.02207 23.6998 9.161 25 12.5 25C15.8388 25 18.9779 23.6998 21.3388 21.3388C23.6998 18.9779 25 15.8388 25 12.5C25 9.161 23.6998 6.02207 21.3388 3.66116ZM6.26659 21.6009C6.7894 18.5907 9.39789 16.3704 12.5 16.3704C15.6023 16.3704 18.2106 18.5907 18.7334 21.6009C16.9586 22.8203 14.8113 23.5352 12.5 23.5352C10.1887 23.5352 8.04138 22.8203 6.26659 21.6009ZM8.52547 10.931C8.52547 8.73928 10.3085 6.95648 12.5 6.95648C14.6915 6.95648 16.4745 8.73947 16.4745 10.931C16.4745 13.1226 14.6915 14.9055 12.5 14.9055C10.3085 14.9055 8.52547 13.1226 8.52547 10.931ZM20.0022 20.5849C19.6079 19.1835 18.825 17.9121 17.7275 16.9189C17.0542 16.3095 16.2878 15.8287 15.4638 15.4898C16.9531 14.5184 17.9396 12.8378 17.9396 10.931C17.9396 7.93171 15.4993 5.49164 12.5 5.49164C9.50069 5.49164 7.06062 7.93171 7.06062 10.931C7.06062 12.8378 8.0471 14.5184 9.53617 15.4898C8.71239 15.8287 7.94582 16.3094 7.27253 16.9188C6.17523 17.9119 5.39207 19.1833 4.99783 20.5847C2.82631 18.568 1.46484 15.6902 1.46484 12.5C1.46484 6.41518 6.41518 1.46484 12.5 1.46484C18.5848 1.46484 23.5352 6.41518 23.5352 12.5C23.5352 15.6904 22.1737 18.5682 20.0022 20.5849Z"
      fill="white"
    />
  </svg>
)
